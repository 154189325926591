/* grid component */
.aem-Grid {
  display: grid;
  gap: @grid[row-gap] @grid[column-gap];
  grid-auto-rows: auto;
  grid-template-columns: repeat(@grid[columns], 1fr);
  width: 100%;
}

/* placeholder for new components */
.aem-Grid-newComponent {
  grid-column: span @grid[columns];
}

/* force showing hidden */
/* stylelint-disable selector-max-specificity */
.aem-GridShowHidden > .aem-Grid > .aem-GridColumn {
  display: block !important; // stylelint-disable-line declaration-no-important
  display: initial !important; // stylelint-disable-line declaration-no-important
}
/* stylelint-enable selector-max-specificity */

/* Generates all the rules for the grid columns up to the given amount of column */
.generate-columns(@columnTotal, @column: 1) when (@column =< @columnTotal) {
  > .aem-GridColumn.aem-GridColumn--@{breakPoint}--@{column} {
    grid-column-end: span @column;
  }

  .generate-columns(@columnTotal, (@column + 1));
}

/* Generates all the rules for the grid column offset up to the given amount of column */
.generate-offsets(@columnTotal, @column: 1) when (@column =< @columnTotal) {
  > .aem-GridColumn.aem-GridColumn--offset--@{breakPoint}--@{column} {
    grid-column-start: @column + 1;
  }

  .generate-offsets(@columnTotal, (@column + 1));
}

/* Generates all the rules for the grid and columns for the given break point and total of columns */
.generate-grid-columns(@breakPoint, @columnTotal) when (@columnTotal > 0) {
  &.aem-Grid--@{columnTotal} {
    .generate-columns(@columnTotal);
    .generate-offsets(@columnTotal);
  }

  &.aem-Grid--@{breakPoint}--@{columnTotal} {
    .generate-columns(@columnTotal);
    .generate-offsets(@columnTotal);
  }
}

/* Generates all the rules for the grids and columns */
.grid-loop(@breakPoint, @columnTotal, @column: 1)
  when
  (@column =< @columnTotal) {
  .generate-grid-columns(@breakPoint, @column);
  .grid-loop(@breakPoint, @columnTotal, (@column + 1));
}

/* API function to be called to generate a grid config */
.generate-grid(@breakPoint, @columnTotal) {
  .grid-loop(@breakPoint, @columnTotal);

  > .aem-GridColumn.aem-GridColumn--@{breakPoint}--newline {
    grid-column: span @grid[columns];
  }

  > .aem-GridColumn.aem-GridColumn--@{breakPoint}--hide {
    /* hide behavior */
    display: none;
  }
}
