.sw-link {
  color: map-get($colors, dark-text);
  font-size: inherit;
  text-decoration: none;

  & > * {
    display: inline;
    vertical-align: middle;
  }

  &:hover {
    color: map-get($colors, dark-text);
    text-decoration: none;
  }

  &:visited {
    color: map-get($colors, link);
    text-decoration: none;
  }

  & .sw-icon {
    width: .85em; // using em so that it sizes to the link font size
    height: .85em; // using em so that it sizes to the link font size
    margin-left: rem(5px);
    transition: margin map-get($transitions, default-ease);
  }

  &:hover .sw-icon {
    margin-left: rem(8px);
  }
}