.cmp-button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: rem(7px 25px);
  margin: rem(15px 0px);
  border: 1.5px solid map-get($colors, dark-text);
  box-sizing: border-box;
  color: map-get($colors, dark-text);
  font-weight: map-get($font-weights, bold);
  font-size: rem(16px);
  line-height: rem(32px);
  text-align: center;
  letter-spacing: rem(2px);
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;

  &:visited {
    color: map-get($colors, dark-text);
    text-decoration: none;
  }

  &:hover {
    background-color: map-get($colors, dark-text);
    border: 1.5px solid map-get($colors, dark-text);
    box-sizing: border-box;
    color: map-get($colors, light-text);
  }
}

.cmp-button-light {
  color: map-get($colors, light-text);
  border: 1.5px solid map-get($colors, light-text);

  &:visited {
    color: map-get($colors, light-text);
  }

  &:hover {
    border: 1.5px solid map-get($colors, dark-text);
  }
}

.button.button--responsive {
  display: inline-block;

  @include media('<screenSmall') {
    display: block;

    .cmp-button {
      justify-content: center;
      width: 100%;
    }
  }
}
