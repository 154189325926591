$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";@import 'STYLE_UTILS/utilityclasses';
@import './globalModules/footer.scss';

//== Reset
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html {
  color: map-get($colors, body);
  font-family: map-get($fonts, default);
  font-size: 1em;
  height: 100%;
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  height: 100%;
  line-height: 1.5;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-rendering: optimizeSpeed;
}
body:not(#privacy) a:not([class]) {
  color: inherit;
  text-decoration: none;
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  display: block;
  max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
/* stylelint-disable declaration-no-important */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0.01ms !important;
  }
}
/* stylelint-enable declaration-no-important */

// Structure
body > .root {
  min-height: 100%;

  > .cmp-container {
    @include grid-wrapper();
  }
}

.cmp-container {
  position: relative; // allows for fullbleed to work
}

.cmp-container__bleed {
  @include fullbleed();
}

// hide nested container fullbleed
.root > .cmp-container .cmp-container .cmp-container .cmp-container .cmp-container__bleed {
  opacity: 0;
}

@import '/corp-aem-sherwin/ui.frontend/src/styles/globalModules/_bg_fullbleed.scss'; @import '/corp-aem-sherwin/ui.frontend/src/styles/globalModules/_button.scss'; @import '/corp-aem-sherwin/ui.frontend/src/styles/globalModules/_icons.scss'; @import '/corp-aem-sherwin/ui.frontend/src/styles/globalModules/_links.scss'; @import '/corp-aem-sherwin/ui.frontend/src/styles/globalModules/_narrow_container.scss'; @import '/corp-aem-sherwin/ui.frontend/src/styles/globalModules/footer.scss';
