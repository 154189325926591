// Text/Title Alignments
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

// Text Decorations
.no-underline,
.no-underline.sw-text a {
  text-decoration: none;
}

// Colors
/* stylelint-disable declaration-no-important */
.light-text {
  color: map-get($colors, light-text) !important;
}

.dark-text {
  color: map-get($colors, dark-text) !important;
}
/* stylelint-enable declaration-no-important */

// Backgrounds
/* stylelint-disable declaration-no-important */
.light-bg {
  background-color: map-get($backgrounds, light-bg) !important;
  position: relative;

  &::before {
    @include fullbleed();

    background: inherit;
    content: '';
  }
}

.sepia-bg {
  background-color: map-get($backgrounds, sepia-bg) !important;
  position: relative;

  &::before {
    @include fullbleed();

    background: inherit;
    content: '';
  }
}

.offset-bg {
  @include offset-background();
}
/* stylelint-enable declaration-no-important */

// Display for containers
.flex .cmp-container {
  display: flex;
}

// Alignment inside containers/grids
.justify-between .cmp-container {
  justify-content: space-between;
}

.items-start .cmp-container {
  align-items: flex-start;
}

.items-center .cmp-container {
  align-items: center;
}

.items-end .cmp-container {
  align-items: flex-end;
}

// Paddings for containers
.cmp-container-padding-x--regular {
  padding-left: rem(50);
  padding-right: rem(50);
}

.cmp-container-padding-y--small {
  padding-top: rem(20);
  padding-bottom: rem(20);
}

.cmp-container-padding-y--medium {
  padding-top: rem(60);
  padding-bottom: rem(60);
}

.cmp-container-padding-y--regular {
  padding-top: rem(80);
  padding-bottom: rem(80);
}

// Styles
.text-intro {
  font-size: rem(18);
  line-height: 1.4444;
}

// Margins for titles
.heading-margin-none .cmp-title__text {
  margin: 0;
}

.h1,
h1 {
  @include heading1;
}

.h2,
h2 {
  @include heading2;
}

.h3,
h3 {
  @include heading3;
}

.h4,
h4 {
  @include heading4;
}

.h5,
h5 {
  @include heading5;
}

// TODO: This needs to be moved
// Corporate Button Styles (should be global)
.corp-button {
  border: 2px solid map-get($borders, dark-border);
  color: map-get($colors, dark-text);
  display: inline-block;
  font-size: rem(16);
  font-weight: map-get($font-weights, bold);
  line-height: rem(19);
  letter-spacing: 0.25px;
  padding: rem(15 24);
  text-decoration: none;

  &:hover {
    background-color: map-get($colors, dark-text);
    color: map-get($colors, light-text);
  }
}
