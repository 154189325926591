/* Can be `flex`, `float`, or `grid` */
/* grid component */
.aem-Grid {
  display: grid;
  gap: 1.5rem 1rem;
  grid-auto-rows: auto;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
}
/* placeholder for new components */
.aem-Grid-newComponent {
  grid-column: span 12;
}
/* force showing hidden */
/* stylelint-disable selector-max-specificity */
.aem-GridShowHidden > .aem-Grid > .aem-GridColumn {
  display: block !important;
  display: initial !important;
}
/* stylelint-enable selector-max-specificity */
/* Generates all the rules for the grid columns up to the given amount of column */
/* Generates all the rules for the grid column offset up to the given amount of column */
/* Generates all the rules for the grid and columns for the given break point and total of columns */
/* Generates all the rules for the grids and columns */
/* API function to be called to generate a grid config */
/* default breakpoint */
.aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--default--1 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--default--1 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--default--2 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--default--2 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--default--2 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--default--2 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--default--3 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--default--3 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--default--3 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--default--3 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--default--3 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--default--3 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--default--4 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--default--5 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--default--6 {
  grid-column-end: span 6;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  grid-column-start: 7;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--default--6 {
  grid-column-end: span 6;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--default--6 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  grid-column-start: 7;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--default--6 {
  grid-column-end: span 6;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--default--7 {
  grid-column-end: span 7;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  grid-column-start: 7;
}
.aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  grid-column-start: 8;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--default--6 {
  grid-column-end: span 6;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--default--7 {
  grid-column-end: span 7;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  grid-column-start: 7;
}
.aem-Grid.aem-Grid--default--7 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  grid-column-start: 8;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--6 {
  grid-column-end: span 6;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--7 {
  grid-column-end: span 7;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--default--8 {
  grid-column-end: span 8;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  grid-column-start: 7;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  grid-column-start: 8;
}
.aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  grid-column-start: 9;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--6 {
  grid-column-end: span 6;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--7 {
  grid-column-end: span 7;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--default--8 {
  grid-column-end: span 8;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  grid-column-start: 7;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  grid-column-start: 8;
}
.aem-Grid.aem-Grid--default--8 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  grid-column-start: 9;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--6 {
  grid-column-end: span 6;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--7 {
  grid-column-end: span 7;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--8 {
  grid-column-end: span 8;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--default--9 {
  grid-column-end: span 9;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  grid-column-start: 7;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  grid-column-start: 8;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  grid-column-start: 9;
}
.aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  grid-column-start: 10;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--6 {
  grid-column-end: span 6;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--7 {
  grid-column-end: span 7;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--8 {
  grid-column-end: span 8;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--default--9 {
  grid-column-end: span 9;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  grid-column-start: 7;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  grid-column-start: 8;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  grid-column-start: 9;
}
.aem-Grid.aem-Grid--default--9 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  grid-column-start: 10;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--6 {
  grid-column-end: span 6;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--7 {
  grid-column-end: span 7;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--8 {
  grid-column-end: span 8;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--9 {
  grid-column-end: span 9;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--default--10 {
  grid-column-end: span 10;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  grid-column-start: 7;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  grid-column-start: 8;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  grid-column-start: 9;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  grid-column-start: 10;
}
.aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--default--10 {
  grid-column-start: 11;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--6 {
  grid-column-end: span 6;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--7 {
  grid-column-end: span 7;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--8 {
  grid-column-end: span 8;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--9 {
  grid-column-end: span 9;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--default--10 {
  grid-column-end: span 10;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  grid-column-start: 7;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  grid-column-start: 8;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  grid-column-start: 9;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  grid-column-start: 10;
}
.aem-Grid.aem-Grid--default--10 > .aem-GridColumn.aem-GridColumn--offset--default--10 {
  grid-column-start: 11;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--6 {
  grid-column-end: span 6;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--7 {
  grid-column-end: span 7;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--8 {
  grid-column-end: span 8;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--9 {
  grid-column-end: span 9;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--10 {
  grid-column-end: span 10;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--default--11 {
  grid-column-end: span 11;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  grid-column-start: 7;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  grid-column-start: 8;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  grid-column-start: 9;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  grid-column-start: 10;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--10 {
  grid-column-start: 11;
}
.aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--default--11 {
  grid-column-start: 12;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--6 {
  grid-column-end: span 6;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--7 {
  grid-column-end: span 7;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--8 {
  grid-column-end: span 8;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--9 {
  grid-column-end: span 9;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--10 {
  grid-column-end: span 10;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--default--11 {
  grid-column-end: span 11;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  grid-column-start: 7;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  grid-column-start: 8;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  grid-column-start: 9;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  grid-column-start: 10;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--10 {
  grid-column-start: 11;
}
.aem-Grid.aem-Grid--default--11 > .aem-GridColumn.aem-GridColumn--offset--default--11 {
  grid-column-start: 12;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--6 {
  grid-column-end: span 6;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--7 {
  grid-column-end: span 7;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--8 {
  grid-column-end: span 8;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--9 {
  grid-column-end: span 9;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--10 {
  grid-column-end: span 10;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--11 {
  grid-column-end: span 11;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--12 {
  grid-column-end: span 12;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  grid-column-start: 7;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  grid-column-start: 8;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  grid-column-start: 9;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  grid-column-start: 10;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--10 {
  grid-column-start: 11;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--11 {
  grid-column-start: 12;
}
.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--default--12 {
  grid-column-start: 13;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--1 {
  grid-column-end: span 1;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--2 {
  grid-column-end: span 2;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--3 {
  grid-column-end: span 3;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--4 {
  grid-column-end: span 4;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--5 {
  grid-column-end: span 5;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--6 {
  grid-column-end: span 6;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--7 {
  grid-column-end: span 7;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--8 {
  grid-column-end: span 8;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--9 {
  grid-column-end: span 9;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--10 {
  grid-column-end: span 10;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--11 {
  grid-column-end: span 11;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--12 {
  grid-column-end: span 12;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--1 {
  grid-column-start: 2;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--2 {
  grid-column-start: 3;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--3 {
  grid-column-start: 4;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--4 {
  grid-column-start: 5;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--5 {
  grid-column-start: 6;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--6 {
  grid-column-start: 7;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--7 {
  grid-column-start: 8;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--8 {
  grid-column-start: 9;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--9 {
  grid-column-start: 10;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--10 {
  grid-column-start: 11;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--11 {
  grid-column-start: 12;
}
.aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--offset--default--12 {
  grid-column-start: 13;
}
.aem-Grid > .aem-GridColumn.aem-GridColumn--default--newline {
  grid-column: span 12;
}
.aem-Grid > .aem-GridColumn.aem-GridColumn--default--hide {
  /* hide behavior */
  display: none;
}
@media (min-width: 64rem) {
  .aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--desktop--1 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--desktop--1 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--desktop--2 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--desktop--2 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--desktop--2 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--desktop--2 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--desktop--3 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--desktop--3 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--desktop--3 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--desktop--3 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--desktop--3 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--desktop--3 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--desktop--4 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--desktop--4 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--desktop--4 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--desktop--4 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--desktop--4 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--desktop--4 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--desktop--4 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--desktop--4 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--desktop--5 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--desktop--5 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--desktop--5 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--desktop--5 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--desktop--5 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--desktop--5 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--desktop--5 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--desktop--5 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--desktop--5 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--desktop--5 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--desktop--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--desktop--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--desktop--6 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--desktop--6 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--desktop--6 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--desktop--6 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--desktop--6 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--desktop--6 > .aem-GridColumn.aem-GridColumn--desktop--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--desktop--6 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--desktop--6 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--desktop--6 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--desktop--6 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--desktop--6 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--desktop--6 > .aem-GridColumn.aem-GridColumn--offset--desktop--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--desktop--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--desktop--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--desktop--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--desktop--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--desktop--7 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--desktop--7 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--desktop--7 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--desktop--7 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--desktop--7 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--desktop--7 > .aem-GridColumn.aem-GridColumn--desktop--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--desktop--7 > .aem-GridColumn.aem-GridColumn--desktop--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--desktop--7 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--desktop--7 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--desktop--7 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--desktop--7 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--desktop--7 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--desktop--7 > .aem-GridColumn.aem-GridColumn--offset--desktop--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--desktop--7 > .aem-GridColumn.aem-GridColumn--offset--desktop--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--desktop--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--desktop--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--desktop--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--desktop--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--desktop--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--desktop--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--desktop--8 > .aem-GridColumn.aem-GridColumn--offset--desktop--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--desktop--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--desktop--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--desktop--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--desktop--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--desktop--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--desktop--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--desktop--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--desktop--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--desktop--9 > .aem-GridColumn.aem-GridColumn--offset--desktop--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--desktop--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--desktop--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--desktop--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--desktop--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--desktop--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--desktop--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--desktop--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--desktop--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--desktop--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--desktop--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--desktop--10 > .aem-GridColumn.aem-GridColumn--offset--desktop--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--desktop--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--desktop--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--desktop--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--desktop--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--desktop--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--desktop--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--desktop--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--desktop--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--desktop--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--desktop--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--desktop--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--desktop--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--desktop--11 > .aem-GridColumn.aem-GridColumn--offset--desktop--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--desktop--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--desktop--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--desktop--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--desktop--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--desktop--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--desktop--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--desktop--12 {
    grid-column-end: span 12;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--12 {
    grid-column-start: 13;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--desktop--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--desktop--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--desktop--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--desktop--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--desktop--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--desktop--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--desktop--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--desktop--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--desktop--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--desktop--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--desktop--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--desktop--12 {
    grid-column-end: span 12;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--desktop--12 > .aem-GridColumn.aem-GridColumn--offset--desktop--12 {
    grid-column-start: 13;
  }
  .aem-Grid > .aem-GridColumn.aem-GridColumn--desktop--newline {
    grid-column: span 12;
  }
  .aem-Grid > .aem-GridColumn.aem-GridColumn--desktop--hide {
    /* hide behavior */
    display: none;
  }
}
@media (min-width: 48em) {
  .aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--1 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--dividedteaser--1 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--2 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--dividedteaser--2 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--2 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--2 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--3 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--dividedteaser--3 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--3 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--3 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--3 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--3 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--4 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--dividedteaser--4 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--4 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--4 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--4 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--4 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--4 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--4 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--dividedteaser--5 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--dividedteaser--5 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--5 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--5 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--5 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--5 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--5 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--5 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--5 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--5 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--dividedteaser--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--dividedteaser--6 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--dividedteaser--6 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--6 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--6 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--6 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--6 > .aem-GridColumn.aem-GridColumn--dividedteaser--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--dividedteaser--6 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--6 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--6 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--6 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--6 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--dividedteaser--6 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--dividedteaser--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--dividedteaser--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--dividedteaser--7 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--dividedteaser--7 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--7 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--7 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--7 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--7 > .aem-GridColumn.aem-GridColumn--dividedteaser--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--dividedteaser--7 > .aem-GridColumn.aem-GridColumn--dividedteaser--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--dividedteaser--7 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--7 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--7 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--7 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--7 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--dividedteaser--7 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--dividedteaser--7 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--dividedteaser--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--dividedteaser--8 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--dividedteaser--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--dividedteaser--9 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--dividedteaser--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--dividedteaser--10 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--dividedteaser--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--dividedteaser--11 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--12 {
    grid-column-end: span 12;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--12 {
    grid-column-start: 13;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--dividedteaser--12 {
    grid-column-end: span 12;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--dividedteaser--12 > .aem-GridColumn.aem-GridColumn--offset--dividedteaser--12 {
    grid-column-start: 13;
  }
  .aem-Grid > .aem-GridColumn.aem-GridColumn--dividedteaser--newline {
    grid-column: span 12;
  }
  .aem-Grid > .aem-GridColumn.aem-GridColumn--dividedteaser--hide {
    /* hide behavior */
    display: none;
  }
}
@media (min-width: 52em) {
  .aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history--1 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history--1 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history--2 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history--2 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history--2 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history--2 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history--3 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history--3 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history--3 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history--3 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history--3 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history--3 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history--4 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history--4 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history--4 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history--4 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history--4 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history--4 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history--4 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history--4 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history--5 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history--5 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history--5 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history--5 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history--5 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history--5 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history--5 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history--5 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history--5 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history--5 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--history--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--history--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--history--6 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history--6 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history--6 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history--6 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history--6 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history--6 > .aem-GridColumn.aem-GridColumn--history--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--history--6 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history--6 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history--6 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history--6 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history--6 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history--6 > .aem-GridColumn.aem-GridColumn--offset--history--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--history--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--history--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--history--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--history--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--history--7 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history--7 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history--7 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history--7 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history--7 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history--7 > .aem-GridColumn.aem-GridColumn--history--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--history--7 > .aem-GridColumn.aem-GridColumn--history--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--history--7 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history--7 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history--7 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history--7 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history--7 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history--7 > .aem-GridColumn.aem-GridColumn--offset--history--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--history--7 > .aem-GridColumn.aem-GridColumn--offset--history--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--history--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--history--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--history--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--offset--history--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--offset--history--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--history--8 > .aem-GridColumn.aem-GridColumn--offset--history--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--history--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--history--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--history--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--history--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--offset--history--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--offset--history--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--offset--history--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--history--9 > .aem-GridColumn.aem-GridColumn--offset--history--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--history--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--history--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--history--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--history--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--history--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--offset--history--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--offset--history--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--offset--history--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--offset--history--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--history--10 > .aem-GridColumn.aem-GridColumn--offset--history--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--history--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--history--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--history--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--history--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--history--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--history--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--offset--history--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--offset--history--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--offset--history--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--offset--history--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--offset--history--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--history--11 > .aem-GridColumn.aem-GridColumn--offset--history--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history--12 {
    grid-column-end: span 12;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history--12 {
    grid-column-start: 13;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--history--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--history--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--history--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--history--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--history--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--history--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--history--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--history--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--history--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--history--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--history--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--history--12 {
    grid-column-end: span 12;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--offset--history--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--offset--history--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--offset--history--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--offset--history--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--offset--history--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--offset--history--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--offset--history--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--offset--history--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--offset--history--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--offset--history--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--offset--history--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--history--12 > .aem-GridColumn.aem-GridColumn--offset--history--12 {
    grid-column-start: 13;
  }
  .aem-Grid > .aem-GridColumn.aem-GridColumn--history--newline {
    grid-column: span 12;
  }
  .aem-Grid > .aem-GridColumn.aem-GridColumn--history--hide {
    /* hide behavior */
    display: none;
  }
}
@media (min-width: 74.3em) {
  .aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history-lg--1 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history-lg--1 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history-lg--2 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history-lg--2 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history-lg--2 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history-lg--2 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history-lg--3 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history-lg--3 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history-lg--3 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history-lg--3 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history-lg--3 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history-lg--3 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history-lg--4 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history-lg--4 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history-lg--4 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history-lg--4 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history-lg--4 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history-lg--4 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history-lg--4 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history-lg--4 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history-lg--5 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history-lg--5 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history-lg--5 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history-lg--5 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history-lg--5 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history-lg--5 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history-lg--5 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history-lg--5 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history-lg--5 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history-lg--5 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--history-lg--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--history-lg--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--history-lg--6 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history-lg--6 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history-lg--6 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history-lg--6 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history-lg--6 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history-lg--6 > .aem-GridColumn.aem-GridColumn--history-lg--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--history-lg--6 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history-lg--6 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history-lg--6 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history-lg--6 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history-lg--6 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history-lg--6 > .aem-GridColumn.aem-GridColumn--offset--history-lg--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--history-lg--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--history-lg--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--history-lg--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--history-lg--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--history-lg--7 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history-lg--7 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history-lg--7 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history-lg--7 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history-lg--7 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history-lg--7 > .aem-GridColumn.aem-GridColumn--history-lg--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--history-lg--7 > .aem-GridColumn.aem-GridColumn--history-lg--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--history-lg--7 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history-lg--7 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history-lg--7 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history-lg--7 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history-lg--7 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history-lg--7 > .aem-GridColumn.aem-GridColumn--offset--history-lg--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--history-lg--7 > .aem-GridColumn.aem-GridColumn--offset--history-lg--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history-lg--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history-lg--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--history-lg--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--history-lg--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--history-lg--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--history-lg--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--history-lg--8 > .aem-GridColumn.aem-GridColumn--offset--history-lg--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history-lg--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history-lg--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history-lg--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--history-lg--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--history-lg--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--history-lg--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--history-lg--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--history-lg--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--history-lg--9 > .aem-GridColumn.aem-GridColumn--offset--history-lg--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history-lg--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history-lg--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history-lg--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history-lg--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--history-lg--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--history-lg--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--history-lg--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--history-lg--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--history-lg--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--history-lg--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--history-lg--10 > .aem-GridColumn.aem-GridColumn--offset--history-lg--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history-lg--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history-lg--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history-lg--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history-lg--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history-lg--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--history-lg--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--history-lg--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--history-lg--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--history-lg--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--history-lg--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--history-lg--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--history-lg--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--history-lg--11 > .aem-GridColumn.aem-GridColumn--offset--history-lg--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history-lg--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history-lg--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history-lg--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history-lg--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history-lg--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history-lg--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--history-lg--12 {
    grid-column-end: span 12;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--12 {
    grid-column-start: 13;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--history-lg--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--history-lg--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--history-lg--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--history-lg--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--history-lg--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--history-lg--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--history-lg--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--history-lg--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--history-lg--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--history-lg--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--history-lg--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--history-lg--12 {
    grid-column-end: span 12;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--history-lg--12 > .aem-GridColumn.aem-GridColumn--offset--history-lg--12 {
    grid-column-start: 13;
  }
  .aem-Grid > .aem-GridColumn.aem-GridColumn--history-lg--newline {
    grid-column: span 12;
  }
  .aem-Grid > .aem-GridColumn.aem-GridColumn--history-lg--hide {
    /* hide behavior */
    display: none;
  }
}
@media (min-width: 767px) {
  .aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--1 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--screenSmall--1 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--screenSmall--1 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--2 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--screenSmall--2 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--screenSmall--2 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--screenSmall--2 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--screenSmall--2 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--3 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--screenSmall--3 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--screenSmall--3 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--screenSmall--3 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--screenSmall--3 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--screenSmall--3 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--screenSmall--3 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--4 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--screenSmall--4 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--screenSmall--4 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--screenSmall--4 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--screenSmall--4 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--screenSmall--4 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--screenSmall--4 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--screenSmall--4 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--screenSmall--4 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--5 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--screenSmall--5 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--screenSmall--5 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--screenSmall--5 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--screenSmall--5 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--screenSmall--5 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--screenSmall--5 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--screenSmall--5 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--screenSmall--5 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--screenSmall--5 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--screenSmall--5 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--screenSmall--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--6 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--screenSmall--6 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--screenSmall--6 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--screenSmall--6 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--screenSmall--6 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--screenSmall--6 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--screenSmall--6 > .aem-GridColumn.aem-GridColumn--screenSmall--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--screenSmall--6 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--screenSmall--6 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--screenSmall--6 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--screenSmall--6 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--screenSmall--6 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--screenSmall--6 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--screenSmall--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--screenSmall--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--7 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--screenSmall--7 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--screenSmall--7 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--screenSmall--7 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--screenSmall--7 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--screenSmall--7 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--screenSmall--7 > .aem-GridColumn.aem-GridColumn--screenSmall--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--screenSmall--7 > .aem-GridColumn.aem-GridColumn--screenSmall--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--screenSmall--7 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--screenSmall--7 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--screenSmall--7 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--screenSmall--7 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--screenSmall--7 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--screenSmall--7 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--screenSmall--7 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--screenSmall--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--screenSmall--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--screenSmall--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--screenSmall--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--screenSmall--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--screenSmall--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--screenSmall--8 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--screenSmall--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--screenSmall--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--screenSmall--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--screenSmall--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--screenSmall--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--screenSmall--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--screenSmall--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--screenSmall--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--screenSmall--9 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--screenSmall--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--screenSmall--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--screenSmall--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--screenSmall--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--screenSmall--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--screenSmall--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--screenSmall--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--screenSmall--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--screenSmall--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--screenSmall--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--screenSmall--10 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--screenSmall--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--screenSmall--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--screenSmall--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--screenSmall--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--screenSmall--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--screenSmall--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--screenSmall--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--screenSmall--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--screenSmall--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--screenSmall--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--screenSmall--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--screenSmall--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--screenSmall--11 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--screenSmall--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--screenSmall--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--screenSmall--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--screenSmall--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--screenSmall--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--screenSmall--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--screenSmall--12 {
    grid-column-end: span 12;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--12 {
    grid-column-start: 13;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--screenSmall--1 {
    grid-column-end: span 1;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--screenSmall--2 {
    grid-column-end: span 2;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--screenSmall--3 {
    grid-column-end: span 3;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--screenSmall--4 {
    grid-column-end: span 4;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--screenSmall--5 {
    grid-column-end: span 5;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--screenSmall--6 {
    grid-column-end: span 6;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--screenSmall--7 {
    grid-column-end: span 7;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--screenSmall--8 {
    grid-column-end: span 8;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--screenSmall--9 {
    grid-column-end: span 9;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--screenSmall--10 {
    grid-column-end: span 10;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--screenSmall--11 {
    grid-column-end: span 11;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--screenSmall--12 {
    grid-column-end: span 12;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--1 {
    grid-column-start: 2;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--2 {
    grid-column-start: 3;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--3 {
    grid-column-start: 4;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--4 {
    grid-column-start: 5;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--5 {
    grid-column-start: 6;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--6 {
    grid-column-start: 7;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--7 {
    grid-column-start: 8;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--8 {
    grid-column-start: 9;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--9 {
    grid-column-start: 10;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--10 {
    grid-column-start: 11;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--11 {
    grid-column-start: 12;
  }
  .aem-Grid.aem-Grid--screenSmall--12 > .aem-GridColumn.aem-GridColumn--offset--screenSmall--12 {
    grid-column-start: 13;
  }
  .aem-Grid > .aem-GridColumn.aem-GridColumn--screenSmall--newline {
    grid-column: span 12;
  }
  .aem-Grid > .aem-GridColumn.aem-GridColumn--screenSmall--hide {
    /* hide behavior */
    display: none;
  }
}
/* force showing hidden components in unhide mode */
/* stylelint-disable selector-max-specificity */
.aem-GridShowHidden > .cmp-container > .aem-Grid > .aem-GridColumn {
  display: block !important;
}
/* stylelint-enable selector-max-specificity */
