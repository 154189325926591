/* Can be `flex`, `float`, or `grid` */
// float (IE11): supports: offsets (...which you shouldn't use...)
// flex (IE11):  supports: vertical alignment, horizontal alignment, gaps (...via margin hacking...)
// grid (no IE): supports: vertical alignment, horizontal alignment, dynamic-consistent row heights, gaps, rowspans
@grid_type: @grid[type];
@import (once) '_@{grid_type}_grid_base.less';

/* default breakpoint */
.aem-Grid {
  .generate-grid(@grid[default], @grid[columns]);
}

.process-breakpoint() when (@grid[generateGridClassesFromBreakpoints] = true) {
  each(@breakpoints, {
    @mq: ~'min-width: @{value}';

    @media (@mq) {
      .aem-Grid {
        .generate-grid(@key, @grid[columns]);
      }
    }
  });
}
.process-breakpoint;

/* force showing hidden components in unhide mode */
/* stylelint-disable selector-max-specificity */
.aem-GridShowHidden > .cmp-container > .aem-Grid > .aem-GridColumn {
  display: block !important; // stylelint-disable-line declaration-no-important
}
/* stylelint-enable selector-max-specificity */
