//
// ICON as element node
// ------------------------------

.cmp-icon {
  display: inline-block;
}

//
// ICON as pseudo element
// ------------------------------

.cmp-icon-pseudo:before,
.cmp-icon-pseudo:after {
  display: inline-block;
  transition: all .3s ease-in-out;
}

.cmp-icon-pseudo--first:before,
.cmp-icon-pseudo--last:after {
  content: '';
}

.cmp-icon-pseudo--first {
  margin-right: rem(4px);
}

.cmp-icon-pseudo--last {
  margin-left: rem(4px);
}

.cmp-icon-pseudo--close:before,
.cmp-icon-pseudo--close:after,
.cmp-icon--close {
  @include icon-close;
}

.cmp-icon-pseudo--carat-up:before,
.cmp-icon-pseudo--carat-up:after,
.cmp-icon--carat-up {
  @include icon-carat-up;
}

.cmp-icon-pseudo--carat-down:before,
.cmp-icon-pseudo--carat-down:after,
.cmp-icon--carat-down {
  @include icon-carat-down;
}

.cmp-icon-pseudo--carat-left:before,
.cmp-icon-pseudo--carat-left:after,
.cmp-icon--carat-left {
  @include icon-carat-left;
}

.cmp-icon-pseudo--carat-right:before,
.cmp-icon-pseudo--carat-right:after,
.cmp-icon--carat-right {
  @include icon-carat-right;
}

.cmp-icon-pseudo--expand:before,
.cmp-icon-pseudo--expand:after,
.cmp-icon--expand {
  @include icon-expand;
}

.cmp-icon-pseudo--collapse:before,
.cmp-icon-pseudo--collapse:after,
.cmp-icon--collapse {
  @include icon-collapse;
}

.cmp-icon-pseudo--pindrop:before,
.cmp-icon-pseudo--pindrop:after,
.cmp-icon--pindrop {
  @include icon-pindrop;
}

.cmp-icon-pseudo--globe:before,
.cmp-icon-pseudo--globe:after,
.cmp-icon--globe {
  @include icon-globe;
}

.cmp-icon-pseudo--person:before,
.cmp-icon-pseudo--person:after,
.cmp-icon--person {
  @include icon-person;
}

.cmp-icon-pseudo--hotspot-circle:before,
.cmp-icon-pseudo--hotspot-circle:after,
.cmp-icon--hotspot-circle {
  @include icon-hotspot-circle;
}

.cmp-icon-pseudo--arrow-left:before,
.cmp-icon-pseudo--arrow-left:after,
.cmp-icon--arrow-left {
  @include icon-arrow-left;
}

.cmp-icon-pseudo--arrow-right:before,
.cmp-icon-pseudo--arrow-right:after,
.cmp-icon--arrow-right {
  @include icon-arrow-right;
}

.cmp-icon-pseudo--arrow-prev:before,
.cmp-icon-pseudo--arrow-prev:after,
.cmp-icon--arrow-prev {
  @include icon-arrow-prev;
}

.cmp-icon-pseudo--arrow-next:before,
.cmp-icon-pseudo--arrow-next:after,
.cmp-icon--arrow-next {
  @include icon-arrow-next;
}

.cmp-icon-pseudo--hamburger-menu:before,
.cmp-icon-pseudo--hamburger-menu:after,
.cmp-icon--hamburger-menu {
  @include icon-hamburger-menu;
}

//
// ANIMATE INTERACTIONS
// ------------------------------

.cmp-icon-pseudo--anim-nudge-last:hover:after {
  transform: translate(4px, 0);
}

.cmp-icon-pseudo--anim-nudge-first:hover:before {
  transform: translate(-4px, 0);
}
