.cmp-footer {
  padding: rem(50px) 0;
  background-color: map-get($backgrounds, footer);
  position: relative;

  &::before {
    @include fullbleed();

    background-color: inherit;
    content: '';
  }
}

.cmp-footer__nav {
  & > .cmp-container {
    @include media('>=screenSmall') {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  & .cmp-linklist__links {
    display: none;

    @include media('>=screenSmall') {
      display: block;
    }
  }

  & .cmp-linklist__links-list {
    margin: 0;
  }

  & .cmp-linklist__item {
    font-weight: map-get($font-weights, normal);
    margin-bottom: rem(15px);
  }

  & .cmp-linklist__item--link {
    color: inherit;
  }

  & .cmp-linklist__header {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: rem(16px);
    font-weight: map-get($font-weights, bold);
    padding: rem(15px) 0;

    @include media('>=screenSmall') {
      cursor: auto;
      font-size: rem(18px);
    }

    &::after {
      @include icon-expand();

      content: '';
      display: block;
      margin-left: auto;
      transition: transform map-get($transitions, default);

      @include media('>=screenSmall') {
        display: none;
      }
    }
  }
}

.cmp-footer__utility {
  margin-top: rem(30px);

  @include media('>=screenSmall') {
    font-size: rem(14px);
    margin-top: rem(70px);
  }

  & .image {
    margin-right: rem(25px);
    margin-top: rem(25px);

    @include media('>=screenSmall') {
      justify-content: center;
      margin-top: 0;
    }
  }

  & .cmp-image__image {
    height: auto;
    max-width: 100%;
    width: auto;
  }

  & .cmp-linklist__item::after {
    content: '|';

    @include media('>=screenSmall') {
      display: none;
    }
  }

  & .cmp-linklist__links-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 rem(15px);

    @include media('>=screenSmall') {
      justify-content: flex-end;
    }
  }

  & .cmp-linklist__item--link {
    color: inherit;
    font-size: rem(12px);
    font-weight: map-get($font-weights, normal);

    @include media('>=screenSmall') {
      font-size: rem(14px);
    }
  }

  & > .cmp-container {
    display: flex;
    flex-direction: column-reverse;

    @include media('>=screenSmall') {
      flex-direction: row;
    }
  }

  & > .cmp-container .cmp-container {
    @include media('>=screenSmall') {
      display: flex;
      flex-direction: column;
    }
  }

  & > .cmp-container > .container {
    @include media('>=screenSmall') {
      margin-left: auto;
    }
  }

  & .cmp-linklist__item {
    margin-left: rem(3px);
  }

  @include media('>=screenSmall') {
    & .cmp-linklist__item + .cmp-linklist__item {
      margin: 0 0 0 rem(15px);
    }

    & .cmp-linklist__item:first-child {
      margin-left: 0;
    }
  }

  & .cmp-linklist__item:last-child::after {
    content: '';
  }

  // This is the copyright notice
  & .text {
    display: none;
    font-size: rem(12px);
    font-weight: map-get($font-weights, normal);

    @include media('>=screenSmall') {
      display: block;
      text-align: right; // for IE11 support - margin-left:auto centers the text
    }
  }
}

.jsa-footer__show-links {
  & + .cmp-linklist__links {
    display: block;
  }

  &::after {
    transform: rotate(45deg);
  }
}
